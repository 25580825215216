<template>
  <vx-card  style="padding-left: 10px">

    <div class="vx-row flex mb-2">
      <div class="vx-col w-full" align="right">
        <vs-button
          align="right"
          @click="openAddpopup()"
          icon-pack="feather"
          icon="icon-edit"
        >Add {{ title }}</vs-button>
      </div>
    </div>
  <div id="data-list-list-view" class="data-list-container">
    <vs-table
      ref="table"
      :sst="true"
      :total="totalDocs"
      :data="users"
      @search="handleSearch"
      @change-page="handleChangePage"
      @sort="handleSort"
      :max-items="dataTableParams.limit"
      :noDataText="noDataText"
      search
    >
      <div slot="header" class="flex flex-wrap-reverse flex-grow justify-between" >
        <div style='float: left'>
          <vs-select placeholder="10" vs-multiple autocomplete v-model="dataTableParams.limit" class="mr-6">
            <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in limitOptions" :clearable="false"/>
          </vs-select>
        </div>
      </div>

      <template slot="thead">
            <vs-th sort-key="name">Title</vs-th>
            <vs-th sort-key="name">Type</vs-th>
          <vs-th sort-key="createdAt">Created At</vs-th>
           <vs-th sort-key="action">Action</vs-th>
      </template>

      <template slot-scope="{data}">
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="data[indextr].title">
                    {{ data[indextr].title}}
                </vs-td>
              <vs-td :data="data[indextr].type">
                {{ data[indextr].type }}
              </vs-td>
              <vs-td :data="data[indextr].createdAt">{{ data[indextr].createdAt | date_formatter }}</vs-td>

              <vs-td :data="data[indextr]._id">
                  <p>
                    <vx-tooltip text="Edit Report Title"  position="left" >
                    <vs-icon class="mr-2" icon-pack="feather" icon="icon-edit" size="small" @click="openUpdatePopup(data[indextr])"></vs-icon>
                    </vx-tooltip>
                    <vx-tooltip text="Delete Report Title"  position="left" >
                    <vs-icon class="mr-2" icon-pack="feather" icon="icon-trash" size="small" @click="openDeleteConfirm(data[indextr]._id)"></vs-icon>
                    </vx-tooltip>
                  </p>
                  </vs-td>
            </vs-tr>
        </template>
    </vs-table>
    <div class="m-2">
        <span class="mr-2">
          {{
          dataTableParams.page * dataTableParams.limit -
          (dataTableParams.limit - 1)
          }}
          -
          {{
            totalDocs - dataTableParams.page * dataTableParams.limit > 0
              ? dataTableParams.page * dataTableParams.limit
              : totalDocs
          }}
          of {{ totalDocs }}
        </span>
      <div class="pagination-div" v-if="serverResponded">
        <paginate
          :page-count="totalPage"
          :click-handler="handleChangePage"
          class="pagination"
          :page-range="9"
          :prevText="'<'"
          :nextText="'>'"
        ></paginate>
      </div>
    </div>
  </div>

 <vs-popup classContent="popup-example"  title="Add Report Title" :active.sync="popupActivo2">
    <vs-input class="w-full mt-4" label="Title" v-model="reportTitle.title" v-validate="'required'" data-vv-scope="reportTitle" data-vv-as="title" data-vv-validate-on="blur" name="title" />
          <span class="text-danger text-sm"  v-show="errors.has('reportTitle.title')"><p>{{ errors.first('reportTitle.title') }}</p></span>
   <div class="mt-4">

   <label class="vs-input--label">Type</label>
     <v-select v-model="reportTitle.type" :clearable="false" :reduce="option => option.value" :options="typeOptions" data-vv-scope="reportTitle" v-validate="'required'" name="type" :dir="$vs.rtl ? 'rtl' : 'ltr'" />
     <span class="text-danger text-sm"  v-show="errors.has('type')">{{ errors.first('type') }}</span>
   </div>
   <vs-button color="success" class="ml-auto mt-4" @click="addMethod"> Add {{ title }}</vs-button>
  </vs-popup>

 <vs-popup classContent="popup-example"  title="Edit Report Title" :active.sync="popupActivo3">
   <vs-input class="w-full mt-4" label="Title" v-model="reportTitle.title" v-validate="'required'" data-vv-scope="reportTitle" data-vv-as="title" data-vv-validate-on="blur" name="title" />
   <span class="text-danger text-sm"  v-show="errors.has('reportTitle.title')"><p>{{ errors.first('reportTitle.title') }}</p></span>
   <div class="mt-4">

     <label class="vs-input--label">Type</label>
     <v-select v-model="reportTitle.type" :clearable="false" :reduce="option => option.value" :options="typeOptions" data-vv-scope="reportTitle" v-validate="'required'" name="type" :dir="$vs.rtl ? 'rtl' : 'ltr'" />
     <span class="text-danger text-sm"  v-show="errors.has('type')">{{ errors.first('type') }}</span>
   </div>
          <vs-button color="success" class="ml-auto mt-4" @click="updateMethod"> Update Level</vs-button>
  </vs-popup>
  </vx-card>
</template>

<script>
import vSelect from 'vue-select'
import { mapActions } from 'vuex';
import moment from "moment";

export default {
  components: {
    'v-select': vSelect,
  },
  data() {
    return {
      title:'Report Title',
      noDataText:'No report titles available..',
      isMounted: false,
      totalDocs: 0,
      currentPage: 1,
      dataTableParams: {
        searchKey: "",
        sort: "name",
        dir: "asc",
        page: 1,
        limit: 25
      },
  reportTitle: {
        _id: '',
        title: '',
        type:'review'
      },
      typeOptions:[
        {
          label:'Booking Issue',
          value:'bookingIssue'
        },
        {
          label:'Booking Student',
          value:'bookingStudent'
        },
        {
          label:'Tutor',
          value:'tutor'
        },
        {
          label:'Review',
          value:'review'
        }
      ],
      popupActivo2: false,
      popupActivo3: false,
      users: [],
      serverResponded: false,
      limitOptions: [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '25', value: 25 },
        { text: '50', value: 50 },
        { text: '100', value: 100 },
      ],
    };
  },
  methods: {
    ...mapActions('report', ['fetchReportTitles','addReportTitle','removeReportTitle','updateReportTitle']),
    moment(date){
      return moment(date)
    },
    getList() {
      let self = this;
      this.fetchReportTitles(self.dataTableParams).then(res => {
        self.users = res.data.data.docs;
        self.totalDocs = res.data.data.pagination.total;
        self.page = res.data.data.pagination.page;
        self.serverResponded = true;
      })

    },
    async addMethod(){
      const isValidation=await this.$validator.validateAll('reportTitle');
      if(isValidation) {
        const title = this.reportTitle.title
        const type = this.reportTitle.type
        this.addReportTitle({title, type}).then(res => {
          this.$vs.notify({
            title: 'Add',
            text: res.data.message,
            iconPack: 'feather',
            icon: 'icon-check-circle',
            color: 'success'
          })
          this.popupActivo2 = false
          this.getList();
        }).catch(err => {
          this.$vs.notify({
            title: 'Failure',
            text: err.response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
          // this.popupActivo2 = false
        })
      }
    },
    async updateMethod(){
      const isValidation=await this.$validator.validateAll('reportTitle');
      if(isValidation) {
        const updateData = {
          id: this.reportTitle._id,
          title: this.reportTitle.title,
          type: this.reportTitle.type
        };
        this.updateReportTitle(updateData).then(res => {
          this.$vs.notify({
            title: 'Update',
            text: res.data.message,
            iconPack: 'feather',
            icon: 'icon-check-circle',
            color: 'success'
          })
          this.popupActivo3 = false
          this.getList();
        }).catch(err => {
          this.$vs.notify({
            title: 'Failure',
            text: err.response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
          // this.popupActivo3 = false
        })
      }
    },
    openAddpopup(){
      this.popupActivo2 = true
      this.reportTitle = { _id: '', title: '', type: 'review'}
    },
    openUpdatePopup(rowData){
      this.popupActivo3 = true
      this.reportTitle._id = rowData._id
      this.reportTitle.title = rowData.title
      this.reportTitle.type = rowData.type
    },
    handleSearch(searching) {
      this.serverResponded = false;
      this.dataTableParams.searchKey = searching;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.getList();
    },
     handleChangePage(page) {
      this.dataTableParams.page = page;
       this.getList();
    },
    handleSort(key, active) {
      this.serverResponded = false;
      this.dataTableParams.sort = key;
      this.dataTableParams.dir = active;
      this.dataTableParams.page = 1;
      this.currentPage = 1;
      this.$refs.table.currentx = 1;
      this.getList();
    },
    createTutorHandler(){
      this.$router.push({ name: 'AddTutor'})
    },
    viewDetailHandler(id) {
      this.$router.push({ name: 'TutorDetails', params: { tutorId: id } })
    },
    editDetailHandler(id) {
      this.$router.push({ name: 'EditTutorDetails', params: { tutorId: id } })
    },
    openDeleteConfirm(reportTitleId){
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: `Confirm Delete`,
        text: `You are about to delete this report title.`,
        accept: this.deleteDetailHandler,
        acceptText: "Delete",
        parameters: reportTitleId
      })
    },
    deleteDetailHandler(reportTitleId) {
      this.removeReportTitle({id:reportTitleId}).then(res => {
        this.$vs.notify({
          title: 'Delete',
          text: res.data.message,
          iconPack: 'feather',
          icon: 'icon-check-circle',
          color: 'success'
        })
      this.getList();
      }).catch(err => {
        this.$vs.notify({
          title: 'Failure',
          text: 'Something went wrong.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      })
    }
  },
  watch: {
  	'dataTableParams.page': function(newVal, oldVal) {
      if(newVal !== oldVal){
        this.dataTableParams.page = newVal;
        this.getList();
      }
    },
    'dataTableParams.limit': function(newlimit, oldLimit) {
      if(newlimit !== oldLimit){
        this.serverResponded = false;
        this.dataTableParams.page = 1;
        this.dataTableParams.limit = newlimit;
        this.getList()
      }
    }
  },
  computed: {
    totalPage: function() {
      return this.totalDocs / this.dataTableParams.limit >
      parseInt(this.totalDocs / this.dataTableParams.limit)
        ? parseInt(this.totalDocs / this.dataTableParams.limit) + 1
        : parseInt(this.totalDocs / this.dataTableParams.limit);
    }
  },
  created() {
    this.getList();
  }
};
</script>
<style scoped>
.actionButtons{
  display: inline-block;
}
.actionButtons .actionButton{
  float: left;
}
</style>
